import React, { useLayoutEffect, useRef } from 'react'

import $ from "jquery";
import details from "../../data_store/details.json";

const Members = () => {
    const fontWeight = useRef(null);
    useLayoutEffect(() => {
        fontWeight.current.style.setProperty("font-weight", "unset", "important");
    }, []);
    return (
        <>
            <div class="container-xxl" style={{ marginTop: "-5rem", marginBottom: "-5rem" }}>
                <div class="px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="text-center mb-5">Our Team Members</h1>
                    </div>
                    <div class="row g-4">

                        {details && details.members.map((member) => {
                            return <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="team-item rounded" style={{ backgroundColor: "#cdd6ed" }}>
                                    <div class="text-center border-bottom p-4">
                                        <img class="img-fluid rounded-circle mb-4" src={"assets/img/teacher/" + member.image} alt="loading..." style={{ height: "15rem", width: "17rem" }} />
                                        <h5>{member.name}</h5>
                                        <span>{member.designation}</span>
                                    </div>
                                </div>
                            </div>
                        })
                        }
                    </div>

                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="text-center mb-5" style={{ fontSize: "xx-large" }} ref={fontWeight}>& many more...</h1>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Members;