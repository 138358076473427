import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';
import Facts from '../components/Facts';
import Features from '../components/Features';
import Faqs from '../components/Faqs';
import BottomBanner from '../components/BottomBanner';
import Learning from '../components/Learning';
import SubHeader from '../components/SubHeader';
import Explore from '../components/explore/Explore';
import Career from '../components/career/career';
import Blog from '../components/blog/Blog';
import AboutUs from '../components/aboutus/AboutUs';
import Terms from '../components/Terms';
import Members from '../components/members/Members';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route exact path='/explore' element={<ExploreRoute />} />
                <Route exact path='/career' element={<CareerRoute />} />
                <Route exact path='/blog' element={<BlogRoute />} />
                <Route exact path='/about-us' element={<AboutUsRoute />} />
                <Route exact path='/members' element={<MembersRoute />} />
                <Route exact path='/terms' element={<TermsRoute />} />
                <Route exact path='/' element={<RootEle />} />
            </Routes>
        </Router >
    );
}

const RootEle = () => {
    return <>
        <Header />
        {/* <Features /> */}
        <Learning />
        <Facts />
        <Team />
        <Testimonial />
        {/* <Faqs /> */}
        <BottomBanner />
        <Footer />
    </>
}

export default AppRoutes;

const ExploreRoute = () => {
    return <>
        <SubHeader />
        <Explore />
        <Footer />
    </>
}

const CareerRoute = () => {
    return <>
        <SubHeader />
        <Career />
        <Footer />
    </>
}

const BlogRoute = () => {
    return <>
        <SubHeader />
        <Blog />
        <Footer />
    </>
}

const AboutUsRoute = () => {
    return <>
        <SubHeader />
        <AboutUs />
        <Footer />
    </>
}

const TermsRoute = () => {
    return <>
        <SubHeader />
        <Terms />
        <Footer />
    </>
}

const MembersRoute = () => {
    return <>
        <SubHeader />
        <Members />
        <Footer />
    </>
}