import React from 'react';
import details from "../../data_store/details.json";

const Career = () => {
    let font = "Times New Roman";
    return <>
        <div class="container-xxl">
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font }}>
                        For Students
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        1. Class 1-12 all subjects, all boards <br />
                        2. Technical Skills - MS Word, pathos, SQL and non-SQL <br />
                        3. Competitive Exam <br />
                        4. Interview Practice <br />
                        5. Spoken English <br />
                    </h6>
                </p>
            </div>

            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "4rem" }}>
                <p style={{ textAlign: "left" }}>
                    <h4 style={{ fontFamily: font }}>
                        For Teachers
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        1. Teachers can apply for any subject.<br />
                        2. No experience is needed, expert gives sessions.<br />
                        3. As per the knowledge from an interview.<br />
                    </h6>
                </p>
            </div>

            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "4rem", marginBottom: "-3rem" }}>
                <p style={{ textAlign: "left" }}>
                    <h4 style={{ fontFamily: font }}>
                        Internships
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        As college students can apply for the internships. <br />
                        - Stipend <br />
                        - LOR <br />
                        - Certificate <br />
                        We give Internships in every departments. <br />
                    </h6>
                </p>
            </div>
        </div>
    </>
}

export default Career;