import React from 'react';
import details from "../../data_store/details.json";

const AboutUs = () => {
    let font = "Times New Roman";
    return <>
        <div class="container-xxl">
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "-3rem", marginBottom: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font, textAlign: "center" }}>
                        About Us
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        DS Knowledge Nation was founded on 5th August 2020. DS Knowledge Nation has built a platform to be a bridge between a student and a teacher. This startup has made the ancient learning system a digital platform. DS Knowledge Nation is an interface platform for connecting students and tutors all over India with online and offline mode classes. Here at DSKN, we offer classes of many subjects with all qualified tutors from various experienced professionals.
                    </h6>
                </p>
            </div>
        </div>
    </>
}

export default AboutUs;