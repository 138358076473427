import React from 'react';
import details from "../data_store/details.json";

const Terms = () => {
    let font = "Times New Roman";
    return <>
        <div class="container-xxl">
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font, textAlign: "center" }}>
                        Terms & Conditions
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        1. First  month salary or fees must be paid to the company for a particular financial year. <br />
                        2. Incase the student(s) does not continue after 1-2 month(s) then the teacher have to pay 60% for that student(s). <br />
                        3. During 2nd tuition, they have to have 50% of the student(s). <br />
                        4. Interns need to do a 3 months of Internship for a certificate & others benefits.<br />
                    </h6>
                </p>
            </div>
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "4rem", marginBottom: "-3rem" }}>
                <p style={{ textAlign: "left" }}>
                    <h4 style={{ fontFamily: font, textAlign: "center" }}>
                        Rules for Tutors @ DS Knowledge Nation
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        1. All the tutors must <span style={{ textDecoration: "underline" }}>
                            <a target='_blank' href={details['company-details']['sign-up-url']}>Fill</a>
                        </span> this form after/before onboarding. <br />

                        2. Payments needs to be clear within te given time. <br />

                        3. Conduct tests monthly atleast twice. <br />

                        4. Missing any classes should be adjusted with the extra classes. <br />

                        5. Make a barrier between professional and personal life. <br />

                        6. Learning should be done with practical exposures. And topics should be covered with live examples. <br /> <br />

                        🚨If we found any violation of these rules and regulations, strict actions will be taken. <br /> <br />

                        🌻We are working on increasing the tutors and students. We will be highly obliged if you also spread the awareness
                    </h6>
                </p>
            </div>
        </div>
    </>
}

export default Terms;