import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import EnquireModal from '../utils/EnquireModal';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const { pathname } = useLocation();

  //for solving the issue - react-router link bring me to the middle of a page, as it stores the previous scroll position
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, [pathname]);

  return (
    <>
      <div class="container-xxl position-relative p-0 overflowX-hide">
        <a href="#" class="float enquiry-a" id="enquiry-btn" onClick={handleShow}>
          <span className='enquiry-span'>Enquiry
            <i class="fa-solid fa-arrow-right my-float"></i>
          </span>
        </a>
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{ width: "90%" }}>
          <Link to="/" class="navbar-brand p-0">
            {/* <h1 class="m-0">DGital</h1> */}
            <img src="assets/img/small-logo.jpg" style={{ borderRadius: "5px" }} />
          </Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav mx-auto py-0">
              <Link to="/" class="nav-item nav-link active">Home</Link>
              <Link to="/career" class="nav-item nav-link">Careers</Link>
              <Link to="/blog" class="nav-item nav-link">Blog</Link>
              <Link to="/about-us" class="nav-item nav-link">About Us</Link>
              {/* <div class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                <div class="dropdown-menu m-0">
                  <a href="team.html" class="dropdown-item">Our Team</a>
                  <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                  <a href="404.html" class="dropdown-item">404 Page</a>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
        {/* <a href="#" class="float enquiry-a" id="enquiry-btn" onClick={handleShow}>
          <span className='enquiry-span'>Enquiry
            <i class="fa-solid fa-arrow-right my-float"></i>
          </span>
        </a> */}
        {/* <a href="#" class="" onClick={() => handleShow}>Enquiry <i class="fa-solid fa-arrow-right"></i></a> */}

        {show ? <EnquireModal show={show} setShow={setShow} /> : ""}
        <div class="container-xxl bg-primary hero-header">
          <div style={{}} className="image-div cls-animate-header-img">
            {/* <img src="" style={{ height: "50%", width: "40%", borderRadius: "20px" }} /> <br /> */}
          </div>

          <div class="px-lg-5">
            <div class="row align-items-end" style={{ marginTop: "-5rem", "--bs-gutter-y": "3rem" }}>
              <div class="col-lg-6 text-center text-lg-start">
                <h1 class="text-white mb-4 animated slideInDown">Grow up your skills by online & offline courses with DSKN</h1>
                <p class="text-white pb-3 animated slideInDown">A platform designed by DS Knowledge Nation builds a bridge between students and teachers through online and offline mode.</p>
                <Link to="/explore" class="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft" style={{ backgroundColor: "white" }}>Explore Path</Link>
                {/* <a href="" class="btn btn-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a> */}
              </div>
              <div class="col-lg-6 text-center text-lg-start">
                <div class=" animated slideInDown">
                  <img class="header-banner-img" src={"assets/img/header-banner.png"} alt="loading..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
