import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";

function EnquireModal(props) {
    const [show, setShow] = useState(props.show);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)
    const [captchaValue, setCaptchaValue] = useState(null)

    const DSKN_BACKEND_MAILER_URL = process.env.REACT_APP_BACKEND_MAILER_URL || 'http://localhost:3001/mail';
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

    function onCaptchaChange(value) {
        setIsCaptchaSuccess(true);
        setCaptchaValue(value);
    }

    const handleClose = () => {
        props.setShow(false);
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateContactNo = (number) => {
        return String(number).match(/^[0-9]{10}$/);
    };

    const showToastMessage = (type, message) => {
        if (type === 'error') {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    const verifyDetails = (details) => {
        let msg = "";
        let keys = Object.keys(details);
        for (let i = 0; i < keys.length; i++) {
            if (details[keys[i]] === "") {
                msg = keys[i] + ": can't be empty!"
                break;
            }
            if (keys[i] === "email" && !validateEmail(details[keys[i]])) {
                msg = "Please enter a valid email address!"
                break;
            }
            if (keys[i] === "contactNo" && !validateContactNo(details[keys[i]])) {
                msg = "Please enter a valid 10 digit contact no!"
                break;
            }
        }
        if (msg === "") {
            return false;
        } else {
            showToastMessage('error', msg);
            return true;
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const details = {
            name: document.getElementById("name").value,
            email: document.getElementById("email").value,
            contactNo: document.getElementById("contactNo").value,
            subject: document.getElementById("subject").value,
            message: document.getElementById("message").value,
            captchaValue: captchaValue
        }
        // console.log(details);
        let status = verifyDetails(details);
        if (status === true)
            return

        const id = toast.loading("Sending.. Please wait.. Don't close the window!")
        const rawResponse = await fetch(DSKN_BACKEND_MAILER_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(details)
        });
        const content = await rawResponse.json();
        // console.log(rawResponse.status, content)
        if (rawResponse.status === 200) {
            toast.update(id, { render: content.message, type: "success", isLoading: false, autoClose: 5000 });
            setTimeout(() => props.setShow(false), 5000);
        } else {
            toast.update(id, { render: content.message, type: "error", isLoading: false, autoClose: 5000, });
        }

    };

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}
            <ToastContainer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact US</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "30rem", overflowY: "auto" }}>
                    <form id="contactForm" name="contactForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" id="name" placeholder="Name" required />
                                </div>
                            </div>
                            <div class="col-md-12"><br />
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" id="email" placeholder="Email" required />
                                </div>
                            </div>
                            <div class="col-md-12"><br />
                                <div class="form-group">
                                    <input type="text" class="form-control" name="contactNo" id="contactNo" placeholder="Contact No" required />
                                </div>
                            </div>
                            <div class="col-md-12"><br />
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                            </div>
                            <div class="col-md-12"><br />
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="7" placeholder="Message" required></textarea>
                                </div>
                            </div>

                            <div class="col-md-12" ><br />
                                {RECAPTCHA_SITE_KEY ? <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={onCaptchaChange}
                                /> : <span style={{ color: "red" }}>Captcha Load Failed! Please Try After Some Time!</span>} <br />
                                <div class="form-group" style={{ float: "right" }}>
                                    <input type="button" value="Close" class="btn btn-secondary" onClick={handleClose} />
                                    <input type="submit" disabled={!isCaptchaSuccessful} style={{ marginLeft: "1rem" }} value="Send Message" class="btn btn-primary" onClick={(e) => handleSubmit(e)} />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EnquireModal;