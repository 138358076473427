import React, { useRef } from 'react'
import $ from "jquery";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// import required modules
import { EffectCoverflow, Pagination, Autoplay, Navigation } from 'swiper/modules';
import details from "../data_store/details.json";

const Testimonial = () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <>
            {/* <!-- Testimonial Start --> */}
            <div class="container-xxl py-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class=" py-3">
                    <p class="section-title text-secondary justify-content-center"><span></span>Testimonial<span></span></p>
                    <h1 class="text-center mb-5">What Say Our Clients!</h1>
                    <Swiper spaceBetween={35}
                        slidesPerView={2}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                        className="mySwiper">

                        {details && details.testimonials.map(items => <SwiperSlide>
                            <div style={{ borderStyle: "dashed", borderColor: "cadetblue", padding: "0.5rem" }} class="row rounded">
                                <div class="testimonial-item bg-light rounded">
                                    <p class="fs-5" style={{ maxHeight: "5.7rem", overflowY: "auto", textAlign: "left" }}><i class="fa fa-quote-left fa-2x text-primary mt-n4 me-3"></i>{items.message}</p>
                                    <hr />
                                    <div class="d-flex align-items-center">
                                        <img class="img-fluid flex-shrink-0 rounded-circle" src={"assets/img/testimonial/" + items.image} style={{ "width": "65px", "height": "65px" }} />
                                        <div class="ps-4">
                                            <h5 class="mb-1">{items.name}</h5>
                                            <span>{items.designation}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>)}
                        <div className="autoplay-progress" slot="container-end">
                            <svg viewBox="0 0 48 48" ref={progressCircle}>
                                <circle cx="24" cy="24" r="20"></circle>
                            </svg>
                            <span ref={progressContent}></span>
                        </div>
                    </Swiper>
                </div>
            </div>
            {/* <!-- Testimonial End --> */}
        </>
    )
}
export default Testimonial;