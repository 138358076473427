import React from 'react';

const Learning = () => {
    return (
        <>
            <div class="container-xxl py-3">
                <div class="py-3 px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        {/* <p class="section-title text-secondary justify-content-center"><span></span>Our Services<span></span></p> */}
                        <h1 class="text-center mb-5">Learning has never been this easy</h1>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="service-item d-flex flex-column rounded">
                                {/* <div class="service-icon flex-shrink-0">
                                    <i class="fa fa-search fa-2x"></i>
                                </div> */}
                                <h3 class="mb-3">1. Search For Home Tutors</h3>
                                <p class="m-0">
                                    View the profiles freely and
                                    connect with your tuition teacher
                                    according to your criteria (tuition
                                    fees, recommendations, reviews,
                                    classes at home or online)</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="service-item d-flex flex-column text-center rounded" style={{ "background-color": "#f0d4dd" }}>
                                <div class="service-icon flex-shrink-0">
                                    <i class="fa fa-search fa-2x"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s" style={{ marginTop: "60px" }}>
                            <div class="service-item d-flex flex-column text-center rounded" style={{ "background-color": "#98c8fe" }}>
                                <div class="service-icon flex-shrink-0">
                                    <i class="fa-regular fa-message fa-2x"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop: "60px" }}>
                            <div class="service-item d-flex flex-column rounded">
                                <h3 class="mb-3">2. Contact For The Best Tuition Near Me</h3>
                                <p class="m-0">
                                    Teachers will get back to you
                                    within hours! And if you can't find
                                    the perfect teacher, our team is
                                    here to help.</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{ marginTop: "60px" }}>
                            <div class="service-item d-flex flex-column rounded">
                                <h3 class="mb-3">3. Organize Your Tuition Nearby</h3>
                                <p class="m-0">Freely schedule your tuition
                                    classes with your teacher in 3
                                    clicks!</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s" style={{ marginTop: "60px" }}>
                            <div class="service-item d-flex flex-column text-center rounded" style={{ "background-color": "#f0d4dd" }}>
                                <div class="service-icon flex-shrink-0">
                                    <i class="fa-regular fa-calendar-check fa-2x"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container-xxl py-3">
                <div class="py-3 px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="text-center mb-5">Advantages of DSKN</h1>
                    </div>
                    <div class="row g-4 text-center">
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="text-center p-4">
                                <img class="img-fluid rounded-circle mb-4" src="assets/img/personalized-learning.png" alt="" />
                                <h5>Personalized Learning</h5>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="text-center p-4">
                                <img class="img-fluid rounded-circle mb-4" src="assets/img/individual-attention.png" alt="" />
                                <h5>Individual Attention</h5>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="text-center p-4">
                                <img class="img-fluid rounded-circle mb-4" src="assets/img/blend-learning.png" alt="" />
                                <h5>Blended Learning</h5>
                            </div>
                        </div>

                    </div >
                </div >
            </div >
        </>
    )
}
export default Learning;