import React from 'react'
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import details from "../data_store/details.json";

const Footer = () => {


  return (
    <>
      {/* <!-- ======= Footer ======= --> */}

      <div class="container-fluid bg-primary text-light footer wow fadeIn overflowX-hide" data-wow-delay="0.1s">
        <div class="px-lg-5" style={{ paddingBottom: "0.5rem", marginTop: "-6rem" }}>
          <div class="row g-5">
            <div class="col-md-6 col-lg-4">
              <p class="section-title text-white h5 mb-4">Address<span></span></p>
              <p><i class="fa fa-map-marker-alt me-3"></i>Kolkata, India</p>
              <p><i class="fa fa-phone-alt me-3"></i>{details['company-details'].mobile}</p>
              <p><i class="fa fa-envelope me-3"></i>{details['company-details'].email}</p>
              <div class="d-flex pt-2">
                <a class="btn btn-outline-light btn-social" target='_blank' href={details['company-details'].facebook}><i class="fab fa-facebook-f"></i></a>
                <a class="btn btn-outline-light btn-social" target='_blank' href={details['company-details'].instagram}><i class="fab fa-instagram"></i></a>
                <a class="btn btn-outline-light btn-social" target='_blank' href={details['company-details'].linkedin}><i class="fab fa-linkedin-in"></i></a>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <p class="section-title text-white h5 mb-4">Categories<span></span></p>
              <a class="btn btn-link" href="#" style={{ pointerEvents: "none" }}>About Us</a>
              {/* <a class="btn btn-link" href="#" style={{ pointerEvents: "none" }}>Privacy Policy</a> */}
              <a class="btn btn-link" href="#" style={{ pointerEvents: "none" }}>Terms & Condition</a>
              <a class="btn btn-link" href="#" style={{ pointerEvents: "none" }}>Career</a>
            </div>
            <div class="col-md-6 col-lg-4">
              <p class="section-title text-white h5 mb-4">Quick Link<span></span></p>
              <Link class="btn btn-link" to="/about-us">About Us</Link>
              {/* <a class="btn btn-link" href="#">Privacy Policy</a> */}
              <Link class="btn btn-link" to="/terms">Terms & Condition</Link>
              <Link class="btn btn-link" to="/career">Career</Link>
            </div>
            {/* <div class="col-md-6 col-lg-3">
              <p class="section-title text-white h5 mb-4">Newsletter<span></span></p>
              <p>Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu</p>
              <div class="position-relative w-100 mt-3">
                <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{ "height": "48px" }} />
                <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
              </div>
            </div> */}
          </div>
        </div>
        <div class="px-lg-5">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a class="border-bottom" href="#">@DSKN</a>, All Right Reserved.
              </div>
              {/* <div class="col-md-6 text-center text-md-end">
                <div class="footer-menu">
                  <a href="">Home</a>
                  <a href="">Cookies</a>
                  <a href="">Help</a>
                  <a href="">FQAs</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color='white' style={{ 'backgroundColor': "#149ddd", "borderRadius": "50px" }} />
    </>
  )
}

export default Footer
