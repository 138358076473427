import React from 'react'
import { Link } from 'react-router-dom';
import $ from "jquery";
import details from "../data_store/details.json";

const Team = () => {
    return (
        <>
            {/* <!-- Team Start --> */}
            <div class="container-xxl py-3">
                <div class="py-3 px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        {/* <p class="section-title text-secondary justify-content-center"><span></span>Meet the Team<span></span></p> */}
                        <h1 class="text-center mb-5">Our Team</h1>
                    </div>
                    <div class="row g-4 text-center">

                        {details && details['team-members'].map((member) => {
                            return <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="team-item rounded team-class" style={{ backgroundColor: "#cdd6ed", display: "inline-block" }}>
                                    <div class="text-center border-bottom p-4">
                                        <img class="img-fluid rounded-circle mb-4" src={"assets/img/team/" + member.image} alt="" />
                                        <h5>{member.name}</h5>
                                        <span>{member.role}</span>
                                    </div>
                                    <div class="d-flex justify-content-center p-4">
                                        <a class="btn btn-square mx-1" target='_blank' href={member.facebook}><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-square mx-1" target='_blank' href={member.instagram}><i class="fab fa-instagram"></i></a>
                                        <a class="btn btn-square mx-1" target='_blank' href={member.linkedin}><i class="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        })
                        }
                        <div style={{ textAlign: "center" }}>
                            <Link to="/members" class="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">More Members..</Link>
                        </div>

                        {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="team-item rounded" style={{ "background-color": "#cdd6ed" }}>
                                <div class="text-center border-bottom p-4">
                                    <img class="img-fluid rounded-circle mb-4" src={Team2} alt="" />
                                    <h5>Jessica Brown</h5>
                                    <span>Web Designer</span>
                                </div>
                                <div class="d-flex justify-content-center p-4">
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="team-item rounded" style={{ "background-color": "#cdd6ed" }}>
                                <div class="text-center border-bottom p-4">
                                    <img class="img-fluid rounded-circle mb-4" src={Team3} alt="" />
                                    <h5>Tony Johnson</h5>
                                    <span>SEO Expert</span>
                                </div>
                                <div class="d-flex justify-content-center p-4">
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="team-item rounded" style={{ "background-color": "#cdd6ed" }}>
                                <div class="text-center border-bottom p-4">
                                    <img class="img-fluid rounded-circle mb-4" src={Team3} alt="" />
                                    <h5>Tony Johnson</h5>
                                    <span>SEO Expert</span>
                                </div>
                                <div class="d-flex justify-content-center p-4">
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}
export default Team;