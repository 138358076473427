import React from 'react'
import {
    MDBCarousel,
    // MDBCarouselInner,
    MDBCarouselItem,
    // MDBCarouselElement,
    // MDBCarouselCaption
} from 'mdb-react-ui-kit';
import details from "../data_store/details.json";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const zoomOutProperties = {
    duration: 3500,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.1,
    arrows: true
};

const BottomBanner = () => {
    let bannerImages1 = [];
    let bannerImages2 = [];
    let totalContents = Math.ceil(details.bottomBanner.totalContents / 2);
    for (let i = 1; i <= totalContents; i++) {
        bannerImages1.push(`assets/img/banner/DS${i}.jpg`)
    }

    for (let i = totalContents + 1; i <= details.bottomBanner.totalContents; i++) {
        bannerImages2.push(`assets/img/banner/DS${i}.jpg`)
    }
    return (
        <>
            <div class="container-xxl py-3">
                <div class="py-3 px-lg-5" style={{ marginBottom: "-3rem" }}>
                    <div class="row g-4">
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <Fade {...zoomOutProperties}>
                                {
                                    bannerImages1.map((item, index) => {
                                        return <div className="each-fade" key={index}>
                                            <div className="container">
                                                <img src={item} class="banner-img" />
                                            </div>
                                            <h2></h2>
                                        </div>
                                    })
                                }
                            </Fade>
                        </div>
                        <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <Fade {...zoomOutProperties}>
                                {
                                    bannerImages2.map((item, index) => {
                                        return <div className="each-fade" key={index}>
                                            <div className="container">
                                                <img src={item} class="banner-img" />
                                            </div>
                                            <h2></h2>
                                        </div>
                                    })
                                }
                            </Fade>
                        </div>
                    </div>
                    {/* <MDBCarousel showIndicators showControls fade style={{ boxShadow: "-2px 5px 10px 11px lightgreen", borderRadius: "12px" }}>
                        {
                            bannerImages.map((item) => {
                                return <MDBCarouselItem className='active' src={item} alt='Loading...' style={{ "maxHeight": "90vh", width: "100%" }}>
                                </MDBCarouselItem>
                            })
                        }

                    </MDBCarousel> */}
                </div>
            </div >
        </>
    )
}
export default BottomBanner;