import React from 'react';
import details from "../../data_store/details.json";

const Blog = () => {
    let font = "Times New Roman";
    return <>
        <div class="container-xxl">
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font }}>
                        Redefining Education: What Makes Our Considering Set Personal Tutoring Service Unique
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>

                        The need for high-quality education and academic support is greater than ever in the fast-paced world of today. Finding a trustworthy and reasonably priced tutoring service has grown to be a difficult challenge for many parents due to the increased demand on pupils to perform well in their academics. Families may find it difficult to provide their children with the necessary educational support when traditional tutoring services have high fees. Our Ed Tech business is dedicated to dismantling these obstacles by offering top-notch tutors at home at a fair price, differentiating us from other businesses in the sector. <br /><br />

                        <h5 style={{ fontFamily: font }}>The Customised Method of Education </h5> <br />

                        Our company's guiding principle is based on the idea that each student is an individual with distinct learning preferences and skills. The at-home tutoring concept eliminates the stress and distractions that are frequently associated with traditional home tutoring and offers kids a familiar and comfortable learning environment. We distinguish ourselves from other tutoring services with our individualised and encouraging approach, which puts the academic achievement and overall growth of each student first.
                    </h6>
                </p>
            </div>

            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "4rem", marginBottom: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font }}>
                        Revolutionizing Education: How Edtech is Transforming the Learning Landscape
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        <h5 style={{ fontFamily: font }}>Introduction to Edtech</h5><br />
                        Technology is ingrained in every part of our lives in this fast-paced digital age, and education
                        is no exception. A new wave of educational technology, or edtech, has developed to change
                        education as more conventional teaching methods grow antiquated. The emergence of
                        educational technology, or edtech, has brought about a dramatic change in the educational
                        landscape in recent years. Edtech is the use of technology to improve teaching and learning
                        processes, therefore transforming the traditional educational landscape. E-learning platforms
                        and online courses are only two examples of how edtech has revolutionized the educational
                        landscape. Edtech is revolutionizing education like never before, with everything from virtual
                        reality simulations and AI-powered tutoring systems to online classes and interactive
                        learning games.<br /><br />
                        <h5 style={{ fontFamily: font }}>Top 10 Edtech Companies in India</h5><br />

                        India, with its thriving education sector, has witnessed a surge in edtech companies that are
                        revolutionizing the way education is delivered. Here are the top 10 edtech companies in
                        India making waves in the industry: <br />
                        1. BYJU's: Known for its engaging online learning app, BYJU's offers video lessons,
                        interactive quizzes, and personalized learning experiences for students of all ages. <br />
                        2. Vedantu: With live online tutoring, Vedantu connects students with qualified teachers
                        in real-time, providing personalized learning experiences and addressing individual
                        learning needs.<br />
                        3. Unacademy: Unacademy is a platform that offers a wide range of online courses
                        and video lectures, delivered by top educators, to help students prepare for
                        competitive exams.<br />
                        4. Toppr: Toppr is an adaptive learning platform that uses artificial intelligence to
                        personalize the learning experience and provides students with customized study
                        plans.<br />
                        5. UpGrad: Focused on professional courses, UpGrad collaborates with industry
                        experts and renowned institutions to offer online programs that enhance career
                        prospects.<br />
                        6. Simplilearn: Simplilearn is a platform that provides online training and certification
                        courses in various fields, such as project management, data science, and digital
                        marketing.<br />
                        7. WhiteHat Jr: WhiteHat Jr aims to empower young minds by teaching them coding
                        and programming skills through live online classes and interactive projects.<br />
                        8. Adda247: Adda247 offers online test preparation courses for various competitive
                        exams, including banking, SSC, railways, and teaching.<br />
                        9. Testbook: Testbook is an online platform that provides comprehensive preparation
                        resources, practice tests, and live doubt-solving sessions for government job exams.<br />
                        10. Cuemath: Cuemath focuses on math education and offers a personalized learning
                        experience through its online platform and home-based learning centers.
                        These edtech companies are revolutionizing education in India by making quality learning
                        accessible, personalized, and engaging
                        Benefits of Edtech in Education
                        Edtech offers a wide range of benefits that positively impact both students and educators.<br /><br />

                        <h5 style={{ fontFamily: font }}>Some of the key benefits of incorporating Edtech in education includes - </h5><br />

                        ➔ Enhanced engagement: Edtech tools, such as gamified learning platforms and
                        interactive simulations, make learning fun and engaging, keeping students motivated
                        and interested in the subject matter.<br />
                        ➔ Personalized learning: Edtech platforms can tailor the learning experience to
                        individual students' needs, providing targeted resources, adaptive assessments, and
                        personalized feedback.<br />
                        ➔ Accessible education: Online learning platforms and mobile apps make education
                        accessible to a wider audience, including those with physical disabilities, those in
                        remote areas, and those with limited resources.<br />
                        ➔ Improved collaboration: Edtech enables students to collaborate with their peers
                        and educators, fostering a sense of community and enhancing their communication
                        and teamwork skills.<br />
                        ➔ Real-world application: Through virtual reality simulations and augmented reality
                        experiences, edtech allows students to apply their knowledge in real-world contexts,
                        enhancing their understanding and practical skills.<br /><br />

                        <h5 style={{ fontFamily: font }}>The Future of Edtech and its Potential Impact</h5><br />

                        The future of Edtech looks promising, with technology continuing to reshape the education
                        landscape. Here are some trends and potential impacts to watch out for:<br />
                        ● Artificial Intelligence (AI) and Machine Learning (ML): AI and ML technologies
                        have the potential to revolutionize education by providing personalized
                        recommendations, intelligent tutoring, and automated assessments. These
                        technologies can analyze vast amounts of data to identify individual learning needs
                        and adapt the learning experience accordingly.<br />
                        ● Virtual Reality (VR) and Augmented Reality (AR): VR and AR technologies offer
                        immersive and interactive learning experiences, allowing students to explore virtual
                        worlds and interact with digital objects. These technologies have the potential to
                        make learning more engaging and memorable.<br />
                        ● Gamification and Game-based Learning: Gamification techniques and
                        game-based learning are being increasingly used in education to enhance student
                        engagement and motivation. By incorporating elements of competition, rewards, and
                        challenges, educators can create a more interactive and enjoyable learning
                        environment.<br />
                        ● Adaptive Learning Platforms: Adaptive learning platforms use data analytics and
                        algorithms to personalize the learning experience for each student. These platforms
                        can identify knowledge gaps, provide targeted interventions, and track progress in
                        real-time, ensuring that students receive the support they need to succeed.<br />
                        ● Lifelong Learning and Continuous Professional Development: Edtech platforms
                        are expanding beyond traditional education to offer lifelong learning opportunities and
                        continuous professional development. Professionals can access online courses,
                        certifications, and micro-credentials to upskill and stay relevant in the fast-paced
                        digital economy.<br /><br />
                        As technology continues to advance, the potential for Edtech to transform education is
                        limitless. It has the power to make learning more accessible, engaging, and personalized,
                        empowering learners of all ages to reach their full potential.<br /><br />
                        CTA: Embrace the revolution in education! Explore the world of Edtech and unlock new
                        possibilities for teaching and learning. Start by researching the top Edtech companies in
                        India and find the right platform that aligns with your institution's goals and objectives.
                        Together, let's shape the future of education with technology.
                    </h6>
                </p>
            </div>
        </div>
    </>
}

export default Blog;