import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import EnquireModal from '../utils/EnquireModal';
import { useLocation } from 'react-router-dom';

const SubHeader = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const { pathname } = useLocation()

  //for solving the issue - react-router link bring me to the middle of a page, as it stores the previous scroll position
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, [pathname]);

  return (
    <>

      <div class="container-xxl position-relative p-0">
        <a href="#" class="float enquiry-a" id="enquiry-btn" onClick={handleShow}>
          <span className='enquiry-span'>Enquiry
            <i class="fa-solid fa-arrow-right my-float"></i>
          </span>
        </a>
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" style={{ width: "90%" }}>
          <Link to="/" class="navbar-brand p-0">
            {/* <h1 class="m-0">DGital</h1> */}
            <img src="assets/img/small-logo.jpg" style={{ borderRadius: "5px" }} />
          </Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav mx-auto py-0">
              <Link to="/" class="nav-item nav-link">Home</Link>
              <Link to="/career" class={location.pathname === "/career" ? "nav-item nav-link active" : "nav-item nav-link"}>Careers</Link>
              <Link to="/blog" class={location.pathname === "/blog" ? "nav-item nav-link active" : "nav-item nav-link"}>Blog</Link>
              <Link to="/about-us" class={location.pathname === "/about-us" ? "nav-item nav-link active" : "nav-item nav-link"}>About Us</Link>
            </div>
          </div>
        </nav>
        {/* <a href="#" class="float enquiry-a" style={{ backgroundColor: "white" }} id="enquiry-btn" onClick={handleShow}>
          <span className='enquiry-span'>Enquiry
            <i class="fa-solid fa-arrow-right my-float"></i>
          </span>
        </a> */}
        {/* <a href="#" class="" onClick={() => handleShow}>Enquiry <i class="fa-solid fa-arrow-right"></i></a> */}

        {show ? <EnquireModal show={show} setShow={setShow} /> : ""}
        <div class="container-xxl bg-primary hero-sub-header">
        </div>
      </div>
    </>
  )
}

export default SubHeader;
