import React from 'react';
import details from "../../data_store/details.json";

const Explore = () => {
    let font = "Times New Roman";
    return <>
        <div class="container-xxl">
            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "-3rem" }}>
                <p style={{ textAlign: "left" }} >
                    <h4 style={{ fontFamily: font }}>
                        Let's introduce a new addition to the #EdTech #industry💡
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        In this age of online package payments💰,  it is no longer possible to sit in front of a teacher and learn📗 . <br />
                        A platform designed by DS Knowledge Nation builds a bridge between students and teachers. This #startup has transformed the traditional #education system into a #digital platform where #students can choose teachers based on their needs and #teachers can have students based on their preferred locations.  <br /> <br />

                        Along with this, DSKN also offers🤠  <br />
                        - Study materials  <br />
                        - Regular practice exams,  <br />
                        - Practical instruction in all areas  <br />
                        - Courses to address doubts  <br />  <br />

                        P.S. "NO" package payments and have all highly qualified teachers with expertise in one-on-one instruction.  <br /> <br />

                    </h6>
                </p>
            </div>

            <div class="px-lg-5" style={{ boxShadow: "-2px 5px 10px 11px lightblue", borderRadius: "12px", padding: "2rem", marginTop: "4rem", marginBottom: "-3rem" }}>
                <p style={{ textAlign: "left" }}>
                    <h4 style={{ fontFamily: font, textAlign: "center" }}>
                        Rules for Tutors @ DS Knowledge Nation
                    </h4><br />
                    <h6 style={{ fontFamily: font }}>
                        1. All the tutors must <span style={{ textDecoration: "underline" }}>
                            <a target='_blank' href={details['company-details']['sign-up-url']}>Fill</a>
                        </span> this form after/before onboarding. <br />

                        2. Payments needs to be clear within te given time. <br />

                        3. Conduct tests monthly atleast twice. <br />

                        4. Missing any classes should be adjusted with the extra classes. <br />

                        5. Make a barrier between professional and personal life. <br />

                        6. Learning should be done with practical exposures. And topics should be covered with live examples. <br /> <br />

                        🚨If we found any violation of these rules and regulations, strict actions will be taken. <br /> <br />

                        🌻We are working on increasing the tutors and students. We will be highly obliged if you also spread the awareness
                    </h6>
                </p>
            </div>
        </div>
    </>
}

export default Explore;