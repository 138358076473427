import React, { useRef, useLayoutEffect } from 'react'

const Facts = () => {
    const liveSession = useRef(null);
    const liveOneToOne = useRef(null);
    const rating = useRef(null);
    const satisfiedStudents = useRef(null);
    // const paddingZero = useRef(null);

    useLayoutEffect(() => {
        liveSession.current.style.setProperty("color", "#20c575", "important");
        rating.current.style.setProperty("color", "#a3d4ff", "important");
        liveOneToOne.current.style.setProperty("color", "#f2de02", "important");
        satisfiedStudents.current.style.setProperty("color", "#3ad900", "important");
        // paddingZero.current.style.setProperty("padding", "0", "important");
    }, []);

    return (
        <>
            <div class="container-xxl bg-primary py-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class="py-3 px-lg-5">
                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="text-center mb-2" style={{ color: "white" }}>1 TO 1 INTERACTION</h1>
                        <p class="section-title justify-content-center" style={{ color: "white", textTransform: "none", fontWeight: "400", textAlignLast: "center" }}><span></span>Every student's success starts with a meaningful connection. We connect learners with the right tutors at the right time, creating a ripple effect of better outcomes for the entire community.<span></span></p>
                    </div>
                    <div class="row g-4 text-center">
                        <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                            <i class="fa-solid fa-computer fa-3x text-secondary mb-3"></i>
                            <h1 class="text-white mb-2" data-toggle="counter-up" ref={liveSession}>1L+</h1>
                            <p class="text-white mb-0">Live sessions and Writing Lab submissions</p>
                        </div>
                        <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                            <i class="fa fa-users-cog fa-3x text-secondary mb-3"></i>
                            <h1 class="text-white mb-2" data-toggle="counter-up" ref={liveOneToOne}>1000+</h1>
                            <p class="text-white mb-0">Hours Of live, one-on-one connection</p>
                        </div>
                        <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                            <i class="fa fa-star fa-3x text-secondary mb-3"></i>
                            <h1 class="text-white mb-2" data-toggle="counter-up" ref={rating}>4.8/5</h1>
                            <p class="text-white mb-0">Average rating students give their tutors</p>
                        </div>
                        <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                            <i class="fa fa-users fa-3x text-secondary mb-3"></i>
                            <h1 class="text-white mb-2" data-toggle="counter-up" ref={satisfiedStudents}>700+</h1>
                            <p class="text-white mb-0">Satisfied Students</p>
                        </div>
                    </div>
                </div>
                {/* <div style={{ marginTop: "1rem" }}>
                    <img src={"assets/img/fact-image.jpg"} style={{ width: "100%", height: "55vh", borderRadius: "10rem 10rem 0px 0px" }} />
                </div> */}
            </div>
        </>
    )
}
export default Facts;